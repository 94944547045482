import { useEffect, useCallback } from 'react';

import { useDispatch, useSelector, Selectors, RootThunks, areFiltersValid } from './store';
import { Stack, Typography, Box, Container, Button, TextField, MenuItem } from '@mui/material';
import { LeagueFilter } from './components/filters/LeagueFilter';
import { TimeFilter } from './components/filters/TimeFilter';
import { ResultsPane } from './components/ResultsPane';
import { CarFilter } from './components/filters/CarFilter';
import { SlotFilter } from './components/filters/SlotFilter';
import { TrackFilter } from './components/filters/TrackFilter';
import { FilterSlice, LeagueSortOrders } from './store/Filters';
import { ScrapeStatus } from './components/ScrapeStatus';
import { TermsAndConditionsLink } from './components/TermsAndConditions';
import { useDevice } from './mobile';
import isEqual from 'react-fast-compare';


function App() {

  const dispatch = useDispatch();

  const { smol } = useDevice();

  const canPerformSearch = useSelector(areFiltersValid);
  const resultsFound = useSelector(Selectors.results.getLeagueEstimate, isEqual);
  const orderBy = useSelector(Selectors.filters.getOrderBy, isEqual);

  const executeSearch = useCallback(() => {
    dispatch(RootThunks.search());
  }, [dispatch]);

  useEffect(() => {
    dispatch(RootThunks.init());
  }, [dispatch]);

  return <Container>
    <Stack gap={3} sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: 2,
      maxWidth: "1200px"
    }}>
      <Box sx={{ maxWidth: "860px", paddingRight: 2, paddingLeft: 2}}>
        <Box sx={{
          display: "flex",
          gap: 3,
        }}>
          <img src="icons/irlf-logo-64.png" alt="Logo" width="64px" height="64px" />
          <Typography variant="h1" style={{
            fontSize: smol ? "28px" : undefined
          }}>iRacing League Directory</Typography>
        </Box>
        <Typography paragraph paddingTop={1} variant="caption">Not affiliated with iRacing Motorsports Simulations.</Typography>
        <Typography variant="body1">
          Having trouble finding the right league for you? This tool can help! Using the filters below, you can search for leagues based on their completed and upcoming sessions. You can filter to leagues that race your favorite cars and tracks at times that are convenient to your busy schedule.
        </Typography>
        <Typography paddingTop={1} variant="body1">
          This data is periodically updated and only includes sessions that are part of a season. It does not include league hosted sessions that were not associated with a season.
        </Typography>
        <Typography paddingTop={1} variant="body1">
          Everything is more fun with friends. Happy hunting!
        </Typography>
      </Box>

      <Box sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 1,
        alignItem: "stretch",
        justifyContent: "center",
      }}>
        <LeagueFilter />
        <TimeFilter />
        <SlotFilter />
        <CarFilter />
        <TrackFilter />
      </Box>
      <Stack direction="row-reverse" gap={4} alignItems="center" sx={{
        width: "100%"
      }}>
        <Button onClick={executeSearch} disabled={!canPerformSearch} variant="contained" sx={{
          width: "240px",
          height: "50px",
          flexShrink: 1
        }} size="large" color="secondary">Find leagues</Button>
        <TextField color="primary" sx={{
          backgroundColor: "#eee",
          minWidth: "160px",
          width: "240px"
        }} size="small" select value={orderBy} label="Order by" onChange={(ev) => {
          dispatch(FilterSlice.actions.setOrderBy(ev.target.value as LeagueSortOrders))
        }} >
          <MenuItem value="session_count">Most sessions</MenuItem>
          <MenuItem value="member_count">Member count</MenuItem>
          <MenuItem value="league_name">League name</MenuItem>
        </TextField>
      </Stack>
      {resultsFound !== null
          ? <Typography>
            {`${resultsFound} result${resultsFound !== 1 ? "s" : ""}`}
          </Typography>
          : null}
      <ResultsPane />
      <Box sx={{
        paddingTop: 16
      }}>
        <ScrapeStatus />
      </Box>
        <Typography>iRacing.directory still under construction. If you find any bugs or have feature suggestions, send them to <a href="mailto:feedback@iracing.directory">feedback@iracing.directory</a>.</Typography>
      <TermsAndConditionsLink />
    </Stack>
  </Container>;
}

export default App;
