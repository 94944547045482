import queryString from "query-string";
import type { APISessionSearchInitRequest, APIStatusResponse, APISessionSearchRequest, APISessionSearchResponse, APIConstantsResponse } from "api";
import firstPageResult from "./testData/firstPageResults.json";
import constantsResult from "./testData/constantsResult.json";

function isDev() {
    return window !== undefined 
    && (window as any)["__reactRefreshInjected"];
}

function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

const dateKeys = new Set(["createdAt", "updatedAt", "startAt"]);

function parseJsonWithDates(value: string) {
    return JSON.parse(value, (k,v) => {
        if(typeof v === "string" && dateKeys.has(k)) {
            return new Date(v);
        } else {
            return v
        }
    })
}

export async function fetchLeagues(req: APISessionSearchRequest) {

    if(isDev()) {
        await sleep(500);
        if("cursor" in req) {
                throw new Error("results page not found")
        } else {
            return parseJsonWithDates(JSON.stringify(firstPageResult)) as APISessionSearchResponse;
        }
    }


    let query = "";
    if ("cursor" in req) {
        query = queryString.stringify({
            cursor: req.cursor
        });
    } else {
        // params have to be all strings
        const params: Partial<Record<keyof APISessionSearchInitRequest, string>> = {};
        params.includeNonRecruiting = req.includeNonRecruiting ? "true" : "false";
        params.pageSize = req.pageSize.toFixed();

        if (req.carIds && req.carIds.length > 0) {
            params.carIds = req.carIds.join(",");
        }

        if (req.future !== undefined) {
            params.future = req.future.toFixed();
        }

        if (req.past !== undefined) {
            params.past = req.past.toFixed();
        }

        if (req.order) {
            params.order = req.order;
        }

        if (req.tz) {
            params.tz = req.tz;
        }

        if (req.days && req.days.length > 0) {
            params.days = req.days.join(",");
        }

        if(req.hours && req.hours.length > 0) {
            params.hours = req.hours.map((h) => h.toFixed()).join(",");
        }

        if (req.trackLayoutIds && req.trackLayoutIds.length > 0) {
            params.trackLayoutIds = req.trackLayoutIds.join(",");
        }

        if (req.minimumMemberCount !== undefined) {
            params.minimumMemberCount = req.minimumMemberCount.toFixed();
        }

        if(req.minSof !== undefined) {
            params.minSof = req.minSof.toFixed();
        }

        if(req.maxSof !== undefined) {
            params.maxSof = req.maxSof.toFixed();
        }

        query = queryString.stringify(params);
    }
    const response = await fetch("/api/search?" + query);

    if (response.ok) {
        const data = await response.text();
        //console.debug(JSON.stringify(data));
        return parseJsonWithDates(data) as APISessionSearchResponse;
    } else {
        throw new Error("Error occurred when searching for league sessions: " + JSON.stringify({
            status: response.status.toFixed() + " " + response.statusText,
            message: await response.text()
        }));
    }
}

export async function fetchCarConstants() {

    if(isDev()) {
        await sleep(1);
        return parseJsonWithDates(JSON.stringify(constantsResult));
    }

    const resp = await fetch("/api/constants");
    if (resp.ok) {
        const data = await resp.text();
        //console.debug(JSON.stringify(data));
        return parseJsonWithDates(data) as APIConstantsResponse;
    } else {
        throw new Error("could not fetch constants");
    }
}


export async function getStatus() {

    if(isDev()) {
        await sleep(100);

        return statusResult;
    }


    const resp = await fetch("/api/status");

    return (await resp.json()) as APIStatusResponse;
}

const statusResult: APIStatusResponse = {
    current: null,
    latest: null
}