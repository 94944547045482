import OpenInNew from "@mui/icons-material/OpenInNew";

export function IRacingOutLink(props: {
    id: string,
    item: "league" | "session_results" | "member"
    size?: "large" | "medium" | "small",
    style?: React.CSSProperties
}) {

    const { id, item, size = "medium" , style} = props;


    const url = (() => {
        switch (item) {
            case "league":
                return `https://members.iracing.com/membersite/member/LeagueView.do?league=${id}`;
            case "session_results":
                return `https://members.iracing.com/membersite/member/EventResult.do?subsessionid=${id}`;
            case "member":
                return `https://members.iracing.com/membersite/member/CareerStats.do?custid=${id}`;
        }
    })();

    const height = (() => {
        switch (size) {
            case "large":
                return "64px";
            case "medium":
                return "32px";
            case "small":
                return "16px";
        }
    })();


    return <a href={url} target="_blank" title="Open on iRacing's member site" rel="noreferrer" style={{
        ...style,
        display: "flex",
        flexDirection: "row",
        gap: "8px",
        alignItems: "center",
        height: height,
    }}>
        <img alt="iRacing Logo" src="icons/iRacing-logo-64x50.png" height="100%" />
        <OpenInNew sx={{
            height,
            width: height }}/>
    </a>





}