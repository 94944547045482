import { useCallback } from "react";
import { Selectors, useDispatch, useSelector } from "../store";
import { StatusThunks } from "../store/Status";
import { Link, Stack, Typography } from "@mui/material";
import type { APIStatusResponse, ScrapeStatus as APIScrapeStatus } from "api";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import isEqual from "react-fast-compare";

dayjs.extend(relativeTime);
dayjs.extend(duration);


function getStatusDetails(status: APIScrapeStatus) {
   
    const figures: string[] = [];
   figures.push(`Cars: ${status.cars.toLocaleString()}`);
   figures.push(`Tracks: ${status.tracks.toLocaleString()}`);
   figures.push(`Leagues: ${status.leagues.toLocaleString()}`);
   figures.push(`Seasons: ${status.seasons.toLocaleString()}`);
   figures.push(`Sessions: ${status.sessions.toLocaleString()}`);

    let headerString = "";
    const finishedAgo = dayjs(status.updatedAt).fromNow();
    const runtime = dayjs.duration(dayjs(status.updatedAt).diff(dayjs(status.createdAt)),"milliseconds").humanize();

    if(status.status === "done") {
        headerString = "Finished " + finishedAgo + ". ";
    } else if(status.status === "failed") {
        headerString = "Failed " + finishedAgo + ". ";
    } else {
        headerString = "Runnning for " + runtime + ". Last updated " + finishedAgo + ". ";
    }

    return headerString + figures.join(", ");
}

function APIStatusResponseDetails(props: {
    status: APIStatusResponse
}) {
    const { status } =props;
    const updating = !isEqual(status.current, status.latest);
    
    return <>
        { status.latest !== null 
        ? <Typography>{"Last: " + getStatusDetails(status.latest)}</Typography>
    : null}
    { status.current !== null && updating
        ? <Typography>{"Current: " + getStatusDetails(status.current)}</Typography>
    : null}
    </>
}

export function ScrapeStatus() {

    const dispatch = useDispatch();
    const scrapeStatus = useSelector(Selectors.status.getStatus);
    const scrapeValue = useSelector(Selectors.status.getValue);
    const refreshStatus = useCallback(() => {
        dispatch(StatusThunks.update());
    }, [dispatch]);

    return <Stack alignItems="center">
        <Typography variant="h6">
            {"Database scrape status: "}
            <Link onClick={refreshStatus} sx={{
                cursor: "pointer",
            }}>refresh</Link>
        </Typography>
        {scrapeStatus === "complete" && scrapeValue === null
            ? <Typography variant="subtitle1">Failed to get status.</Typography>
            : null}
        {scrapeStatus === "idle"
            ? <Typography variant="subtitle1">Refresh to get latest status.</Typography>
            : null}
        {scrapeStatus === "pending"
            ? <Typography variant="subtitle1">Fetching status...</Typography>
            : null}
        {scrapeStatus === "complete" && scrapeValue !== null
            ? <APIStatusResponseDetails status={scrapeValue} />
            : null}
    </Stack>
}