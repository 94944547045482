import { useContext, useState, useMemo, useCallback } from "react";
import { CarConstants } from "../../carConstants";
import { useDispatch, useSelector, Selectors } from "../../store";
import { SxProps, Box, ListItemText, Checkbox, ListItemIcon, List, ListItem, ListItemButton, TextField, Stack, Typography, Link } from "@mui/material";
import isEqual from "react-fast-compare";
import React from "react";
import { Filter } from "./Filter";
import { RaceCategory } from "api";
import { CategorySelector } from "./CategorySelector";
import { FilterSlice } from "../../store/Filters";

function CarSelectionListItem(props: {
    carId: string,
    selected: boolean
}) {
    const dispatch = useDispatch();
    const { carId, selected } = props;
    const carConstants = useContext(CarConstants);
    const carName = carConstants.cars[carId].name;

    const toggleSelected = useCallback(() => {
        dispatch(FilterSlice.actions.toggleCar(carId));
    }, [dispatch, carId]);

    return <ListItem disablePadding disableGutters >
        <ListItemButton role={undefined} onClick={toggleSelected} dense>
            <ListItemIcon sx={{
                minWidth: "24px"
            }}>
                <Checkbox
                    checked={selected}
                    disableRipple size="small"
                />
            </ListItemIcon>
            <ListItemText primary={carName} />
        </ListItemButton>
    </ListItem>
}

const CarSelectionListItemMemo = React.memo(CarSelectionListItem);

function CarSelectionMenu(props: {
    shownCarIds: string[],
    sx: SxProps
}) {
    const selectedCars = useSelector(Selectors.filters.getCarIds, isEqual);

    return <Box
        sx={{
            overflowY: "scroll",
            ...props.sx,
        }} className="listbox">
        <List>
            {props.shownCarIds.map((carId) => (
                <CarSelectionListItemMemo carId={carId} key={`car-${carId}`} selected={selectedCars.has(carId)} />

            ))}
        </List>
    </Box>

}

export function CarFilter() {
    const carConstants = useContext(CarConstants);
    const selectedCars = useSelector(Selectors.filters.getCarIds);
    const dispatch = useDispatch();

    const clearSelection = useCallback(() => {
        dispatch(FilterSlice.actions.setCarIds(new Set()));
    }, [dispatch]);

    const [filterBox, setFilterBox] = useState("");

    const [includeRoad, setIncludeRoad] = useState(true);
    const [includeDirtRoad, setIncludeDirtRoad] = useState(true);
    const [includeOval, setIncludeOval] = useState(true);
    const [includeDirtOval, setIncludeDirtOval] = useState(true);

    const sortedCarsByName = useMemo(() => {

        const carsAndIds = [] as {
            id: string,
            name: string,
            categories: RaceCategory[]
        }[];
        for (const carId in carConstants.cars) {
            carsAndIds.push({
                id: carId,
                name: carConstants.cars[carId].name,
                categories: carConstants.cars[carId].categories,
            });
        }

        carsAndIds.sort((a, b) => {
            const aIsSpecial = a.name.startsWith('[');
            const bIsSpecial = b.name.startsWith('[');

            if (aIsSpecial === bIsSpecial) {
                return (a.name.localeCompare(b.name));
            } else if (aIsSpecial) {
                return 1;
            } else if (bIsSpecial) {
                return -1;
            } else {
                return 0;
            }
        });

        return carsAndIds;

    }, [carConstants]);

    const filteredCarIds = useMemo(() => {
        return sortedCarsByName.filter((sc) => {
            if (filterBox !== "" && sc.name.toLowerCase().indexOf(filterBox.toLowerCase()) < 0) {
                return false;
            }

            if (includeRoad && sc.categories.includes("road")) {
                return true;
            }
            if (includeOval && sc.categories.includes("oval")) {
                return true;
            }
            if (includeDirtRoad && sc.categories.includes("dirt_road")) {
                return true;
            }
            if (includeDirtOval && sc.categories.includes("dirt_oval")) {
                return true;
            }

            return false;
        }).map((sc) => sc.id);
    }, [sortedCarsByName, filterBox, includeRoad, includeOval, includeDirtRoad, includeDirtOval]);

    const selectedCarsCount = selectedCars.size;
    const shownCarsCount = filteredCarIds.length;
    const totalCarsCount = sortedCarsByName.length;

    return <Filter title="Cars">
        <TextField
            value={filterBox}
            label="Search car names"
            onChange={(ev) => { setFilterBox(ev.target.value); }}
            size="small"
        />
        <Stack sx={{
            width: "100%",
            justifyContent: "space-evenly"
        }} direction="row">
            <CategorySelector
                road={includeRoad} setRoad={setIncludeRoad}
                oval={includeOval} setOval={setIncludeOval}
                dirtRoad={includeDirtRoad} setDirtRoad={setIncludeDirtRoad}
                dirtOval={includeDirtOval} setDirtOval={setIncludeDirtOval}
            />
        </Stack>
        <CarSelectionMenu shownCarIds={filteredCarIds} sx={{
            flexBasis: 0,
            flexGrow: 2,
            minHeight: "180px",
        }} />
        <CarSelectionSummary selectedCars={selectedCarsCount} shownCars={shownCarsCount} totalCars={totalCarsCount} onClear={clearSelection} />

    </Filter>
}

function CarSelectionSummary(props: {
    selectedCars: number,
    shownCars: number,
    totalCars: number,
    onClear: () => void
}) {
    const { selectedCars, shownCars, totalCars, onClear } = props;

    const carCount = selectedCars === 0 ? "All" : selectedCars.toLocaleString();

    return <Box sx={{
        display: "flex",
        gap: 0.5,
        width: "100%"
    }}>
        <Typography variant="caption">{`${carCount} ${selectedCars === 1 ? "car" : "cars"} selected.`}</Typography>
        {shownCars !== totalCars ? <Typography variant="caption">{`(${shownCars} of ${totalCars} visible)`}</Typography> : null}
        {selectedCars > 0 ? <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
            <Link variant="caption" onClick={onClear} sx={{ cursor: "pointer" }}>Clear</Link>
        </Box> : null}
    </Box>
}