import type { APIStatusResponse } from "api";
import { getStatus } from "../remote";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const StatusThunks = {
  update: createAsyncThunk("status/update", async () => {
    const response = await getStatus();
    return response;
  }),
};

export const StatusSlice = createSlice({
  name: "status",
  initialState: {
    value: null as APIStatusResponse | null,
    status: "idle" as "idle" | "pending" | "complete",
    error: null as unknown | null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(StatusThunks.update.pending, (state) => {
        state.status = "pending";
        state.value = null;
        state.error = null;
      })
      .addCase(StatusThunks.update.fulfilled, (state, action) => {
        state.value = action.payload;
        state.status = "complete";
      })
      .addCase(StatusThunks.update.rejected, (state, action) => {
        state.status = "complete";
        state.error = action.payload;
      });
  },
});

type StatusState = ReturnType<typeof StatusSlice['reducer']>

export const StatusSelectors = {
    getStatus: (state: StatusState) => {
        return state.status;
    },
    getValue: (state: StatusState) => {
        return state.value;
    }
}
