import { useCallback } from "react";
import { Selectors, useDispatch, useSelector } from "../../store";
import { Box, Checkbox, FormControlLabel, Stack, TextField, Tooltip } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import { Filter } from "./Filter";
import { FilterSlice } from "../../store/Filters";

export function LeagueFilter() {
    const dispatch = useDispatch();
    const recruitingOnly = useSelector(Selectors.filters.getRecruitingOnly);

    const toggleRecruitingOnly = useCallback(() => {
        dispatch(FilterSlice.actions.toggleRecruitingOnly())
    }, [dispatch]);

    const restrictToSize = useSelector(Selectors.filters.getRestrictToLeagueSize);
    const toggleRestrictToSize = useCallback(() => {
        dispatch(FilterSlice.actions.toggleRestrictToSize());
    }, [dispatch])

    const restrictToSizeAmount = useSelector(Selectors.filters.getMinimumMemberCount);

    const restrictBySof = useSelector(Selectors.filters.getRestrictToSof);
    const toggleRestrictBySof = useCallback(() => {
        dispatch(FilterSlice.actions.toggleRestrictToSof());
    }, [dispatch]);

    const minSof = useSelector(Selectors.filters.getMinimumSof);
    const maxSof = useSelector(Selectors.filters.getMaximumSof);

    return <Filter title="League">
        <Stack gap={0} sx={{
            height: "100%"
        }}>
            <FormControlLabel
                sx={{ paddingBottom: 1 }}
                control={
                    <Checkbox checked={recruitingOnly} onClick={toggleRecruitingOnly} />
                }
                label="Recruiting only" />

            <FormControlLabel control={
                <Checkbox checked={restrictToSize} onClick={toggleRestrictToSize} />
            }
                label="Minimum size" />
            <TextField
                type="number"
                error={restrictToSizeAmount < 0}
                label="Members"
                value={restrictToSizeAmount}
                onChange={(ev) => {
                    dispatch(FilterSlice.actions.setMinimumMemberCount(parseInt(ev.target.value)));
                }}
                disabled={!restrictToSize}
                size="small"
                sx={{
                    paddingBottom: 1
                }} />
            <Box sx={{
                display: "flex",
                gap: 1,
                alignItems: "center"
            }}>
                <FormControlLabel sx={{
                    flexGrow: 1,
                }} label="Restrict to SoF" control={<Checkbox checked={restrictBySof} onClick={toggleRestrictBySof} />} />
                <Tooltip title="Strength of field is an estimated average iRating for all participants in a session. Will only match past sessions where results are available.">
                    <HelpIcon fontSize="small" sx={{
                        opacity: 0.3
                    }} />
                </Tooltip>
            </Box>
            <Box sx={{
                display: "flex",
                gap: 1,
                alignItems: "center"
            }}>
                <TextField
                    type="number"
                    error={minSof > maxSof}
                    label="Min"
                    value={minSof}
                    onChange={(ev) => {
                        dispatch(FilterSlice.actions.setMinimumSof(parseInt(ev.target.value)));
                    }}
                    disabled={!restrictBySof}
                />
                <TextField
                    type="number"
                    error={minSof > maxSof}
                    label="Max"
                    value={maxSof}
                    onChange={(ev) => {
                        dispatch(FilterSlice.actions.setMaximumSof(parseInt(ev.target.value)));
                    }}
                    disabled={!restrictBySof}
                />
            </Box>
        </Stack>
    </Filter>
}