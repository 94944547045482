import { createContext } from "react";
import type { APIConstantsResponse } from "api";

const defaultConstants : APIConstantsResponse = {
    cars: {},
    carClasses: {},
    trackLayouts: {},
    tracks: {}
}

export const CarConstants = createContext(defaultConstants);
