import { useCallback } from "react";
import { useDispatch, useSelector, Selectors } from "../../store";

import { FilterSlice, TIME_SEARCH_PERIOD } from "../../store/Filters";
import { Checkbox, FormControlLabel, MenuItem, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import { Filter } from "./Filter";

export function TimeFilter() {
    const dispatch = useDispatch();

    const includePastSessions = useSelector(Selectors.filters.getIncludePastSessions);
    const toggleIncludePastSessions = useCallback(() => {
        dispatch(FilterSlice.actions.toggleIncludePast());
    }, [dispatch]);

    const includeAllPastSessions = useSelector(Selectors.filters.getIncludeAllPastSessions);
    const pastSessionWindow = useSelector(Selectors.filters.getTimeWindowPast);
    const pastSessionWindowSize = useSelector(Selectors.filters.getTimeWindowPastPeriod);

    const includeFutureSessions = useSelector(Selectors.filters.getIncludeFutureSessions);
    const toggleIncludeFutureSessions = useCallback(() => {
        dispatch(FilterSlice.actions.toggleIncludeFuture());
    }, [dispatch]);

    const includeAllFutureSessions = useSelector(Selectors.filters.getIncludeAllFutureSessions);
    const futureSessionWindow = useSelector(Selectors.filters.getTimeWindowFuture);
    const futureSessionWindowSize = useSelector(Selectors.filters.getTimeWindowFuturePeriod);

    return <Filter title="Range">
        <Stack gap={0}>

            <FormControlLabel control={<Checkbox checked={includeFutureSessions} onClick={toggleIncludeFutureSessions} />} label="Include future sessions" />
            <RadioGroup row value={includeAllFutureSessions ? "all" : "range"} onChange={(ev) => {
                dispatch(FilterSlice.actions.setIncludeAllFutureSessions(ev.target.value === "all"));
            }}>
                <FormControlLabel control={<Radio />} value="all" label="All" disabled={!includeFutureSessions} />
                <FormControlLabel control={<Radio />} value="range" label="Range" disabled={!includeFutureSessions} />
            </RadioGroup>
            <Stack direction="row" gap={1}>
                <TextField size="small" type="number" hiddenLabel value={futureSessionWindow} onChange={(ev) => {
                    dispatch(FilterSlice.actions.setTimeWindowFuture(parseInt(ev.target.value)));
                }} disabled={!includeFutureSessions || includeAllFutureSessions} />
                <TextField size="small" select hiddenLabel value={futureSessionWindowSize} onChange={(ev) => {
                    dispatch(FilterSlice.actions.setTimeWindowFuturePeriod(ev.target.value as TIME_SEARCH_PERIOD))
                }} disabled={!includeFutureSessions || includeAllFutureSessions} sx={{
                    width: "240px"
                }}>
                    <MenuItem value="days">Days</MenuItem>
                    <MenuItem value="weeks">Weeks</MenuItem>
                    <MenuItem value="months">Months</MenuItem>
                    <MenuItem value="years">Years</MenuItem>
                </TextField>
            </Stack>

            <FormControlLabel sx={{ paddingTop: 2 }} control={<Checkbox checked={includePastSessions} onClick={toggleIncludePastSessions} />} label="Include past sessions" />
            <RadioGroup row value={includeAllPastSessions ? "all" : "range"} onChange={(ev) => {
                dispatch(FilterSlice.actions.setIncludeAllPastSessions(ev.target.value === "all"));
            }}>
                <FormControlLabel control={<Radio />} value="all" label="All" disabled={!includePastSessions} />
                <FormControlLabel control={<Radio />} value="range" label="Range" disabled={!includePastSessions} />
            </RadioGroup>
            <Stack direction="row" gap={1}>
                <TextField size="small" type="number" hiddenLabel value={pastSessionWindow} onChange={(ev) => {
                    dispatch(FilterSlice.actions.setTimeWindowPast(parseInt(ev.target.value)));
                }} disabled={!includePastSessions || includeAllPastSessions} />
                <TextField size="small" hiddenLabel select value={pastSessionWindowSize} onChange={(ev) => {
                    dispatch(FilterSlice.actions.setTimeWindowPastPeriod(ev.target.value as TIME_SEARCH_PERIOD))
                }} disabled={!includePastSessions || includeAllPastSessions} sx={{
                    width: "240px"
                }}>
                    <MenuItem value="days">Days</MenuItem>
                    <MenuItem value="weeks">Weeks</MenuItem>
                    <MenuItem value="months">Months</MenuItem>
                    <MenuItem value="years">Years</MenuItem>
                </TextField>
            </Stack>
            <Typography sx={{ paddingTop: 1 }} variant="caption">To reduce server load, queries are limited to approx. 3 months of past session data.</Typography>
        </Stack>
    </Filter>
}