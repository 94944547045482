import { PayloadAction } from "@reduxjs/toolkit";

type Getters<T> = {
    [k in keyof T & string as `get${Capitalize<k>}`]: (_:T) => T[k]
}

type SetterActions<T> = {
    [k in keyof T & string as `set${Capitalize<k>}`]: (s:T, a: PayloadAction<T[k]>) => void;
}

function toGetterString(propertyName: string) {
    return "get" + propertyName.substring(0,1).toUpperCase() + propertyName.substring(1);
}

function toSetterString(propertyName: string) {
    return "set" + propertyName.substring(0,1).toUpperCase() + propertyName.substring(1);
}

export function createRecordSelectors<T>(prototype: T): Getters<T> {
    const getters = {} as any;

    for(const key in prototype) {
        if(typeof key === "string") {
            const getterName = toGetterString(key) as keyof Getters<T>;
            getters[getterName] = (_:T) => (_[key]);
        }
    }

    return getters as Getters<T>;
}

type SliceSelectors<ROOT, SELS extends Record<string, (_:any) => any>> = {
    [k in keyof SELS]: (_:ROOT) => ReturnType<SELS[k]>
}

export function createSliceSubselectors<ROOT, SLICE, SELS extends Record<string, (_:SLICE) => any>>
(selectSlice: (_:ROOT) => SLICE, selectors: SELS) {
    const subsels = {} as SliceSelectors<ROOT, SELS>;

    for(const selectorName in selectors) {
        subsels[selectorName] = (root) => selectors[selectorName](selectSlice(root));
    }

    return subsels;
}

export function createRecordSetters<T>(prototype: T): SetterActions<T> {
    const setters = {} as any;

    for(const propertyName in prototype) {
        setters[toSetterString(propertyName)] = (state: T, action: PayloadAction<any>) => {
            state[propertyName] = action.payload;
        }
    }

    return setters as SetterActions<T>;
}