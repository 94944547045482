import { Card, CardContent, CardHeader, IconButton, IconButtonProps, Tooltip, styled } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactNode, useCallback, useState } from "react"
import { useDevice } from "../../mobile";

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));


export function Filter(props: {
    title: string,
    helper?: string,
    children: ReactNode,
    collapsible?: boolean
}) {
    const { title, helper, children, collapsible = false } = props;

    const [open, setOpen] = useState(false);
    const toggleOpen = useCallback(() => {
        setOpen((open) => (!open));
    }, [setOpen]);

    const { touch } = useDevice();

    const action = (helper && !touch) ? <Tooltip title="All times are in your local time zone.">
        <HelpIcon sx={{
            opacity: 0.3
        }} />
    </Tooltip> : undefined;

    const avatar = collapsible ? <ExpandMore expand={open} onClick={toggleOpen}>
        <ExpandMoreIcon />
    </ExpandMore> : undefined;

    return <Card sx={{
        display: "flex",
        flexDirection: "column",
        flexBasis: "0",
        flexShrink: 0,
        flexGrow: 1,
        minWidth: "224px",
        maxWidth: "300px"
    }}>
        <CardHeader title={title} action={action} sx={{
            display: "flex",
            alignItems: "center",
        }} avatar={avatar} 
            subheader={(helper && touch) ? helper : undefined}/>
        {/* <Collapse in={!collapsible || open}> */}
            <CardContent sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                flexBasis: 0,
                flexGrow: 1,
            }}>
                {children}
            </CardContent>
        {/* </Collapse> */}
    </Card>

}