import { Box, Dialog, DialogTitle, Link, Typography } from "@mui/material"
import { useCallback, useState } from "react";

function TermsAndConditionsDialog(props: {
    open: boolean,
    onClose: () => void
}) {
    return <Dialog {...props}>
        <DialogTitle>Terms and Conditions</DialogTitle>
        <Box sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 2, display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography>
                Please read these Terms and Conditions carefully before using the website. These Terms and Conditions govern your access and use of the website and constitute a legal agreement between you and iRacing League Directory. By accessing or using the website, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these Terms and Conditions, you must not use the website.
            </Typography>

            <Typography variant="h5">Introduction</Typography>
            <Typography>
                The website iRacing League Directory (referred to as "we," "us," or "our") does not collect any user data or use tracking cookies other than those necessary for the functioning of the website. We respect your privacy and are committed to providing a secure and safe browsing experience.
            </Typography>

            <Typography variant="h5">Use of the Website</Typography>
            <Typography variant="h6">Content Accuracy</Typography>
            <Typography>
                We strive to provide accurate and up-to-date information on the website. However, we do not guarantee the accuracy, completeness, or reliability of any content available on the website. The information provided on the website is for general informational purposes only and should not be considered as professional advice.
            </Typography>

            <Typography variant="h6">Intellectual Property</Typography>
            <Typography>
                All intellectual property rights, including copyrights, trademarks, and logos, displayed on the website are the property of their respective owners. You may not use, reproduce, or distribute any content from the website without obtaining prior written permission from the respective owners.
            </Typography>

            <Typography variant="h6">Prohibited Activities</Typography>
            <Typography>
                You agree not to engage in any of the following activities while using the website:
                <ul>
                    <li>Violating any applicable laws, regulations, or third-party rights.</li>
                    <li>Attempting to gain unauthorized access to the website or its servers.</li>
                    <li>Interfering with the proper functioning of the website.</li>
                    <li>Uploading or transmitting any malicious software, viruses, or harmful code.</li>
                    <li>Engaging in any activity that could damage, disable, or impair the website's infrastructure or user experience.</li>
                </ul>
            </Typography>

            <Typography variant="h5">Third-Party Websites and Content</Typography>
            <Typography>
                The website may contain links to third-party websites or display content provided by third parties. We do not endorse or assume any responsibility for the accuracy, reliability, or legality of any content or services provided by third parties. Your interaction with any third-party websites or content is solely at your own risk.
            </Typography>

            <Typography variant="h5">Limitation of Liability</Typography>
            <Typography>
                To the maximum extent permitted by law, we shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or relating to the use of the website, even if we have been advised of the possibility of such damages.
            </Typography>

            <Typography variant="h5">Indemnification</Typography>
            <Typography>
                You agree to indemnify, defend, and hold us harmless from and against any claims, liabilities, damages, losses, costs, or expenses arising out of or in connection with your use of the website or any violation of these Terms and Conditions.
            </Typography>

            <Typography variant="h5">Modifications and Termination</Typography>
            <Typography>
                We reserve the right to modify, suspend, or terminate the website or any part of it at any time without prior notice. We may also update these Terms and Conditions from time to time. It is your responsibility to review the most current version of the Terms and Conditions when using the website.
            </Typography>

            <Typography variant="h5">Governing Law and Jurisdiction</Typography>

            <Typography>
                These Terms and Conditions shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising out of or in connection with these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts in [Jurisdiction].

            </Typography>

            <Typography variant="h5">Severability</Typography>
            <Typography>
                If any provision of these Terms and Conditions is found to be invalid, illegal, or unenforceable, the remaining provisions shall continue to be valid and enforceable to the fullest extent permitted by law.
            </Typography>

            <Typography variant="h5">Entire Agreement</Typography>
            <Typography>
                These Terms and Conditions constitute the entire agreement between you and iRacing.directory regarding the use of the website and supersede any prior or contemporaneous agreements, communications, or understandings, whether written or oral.</Typography>
            <Typography variant="h5">Contact Us</Typography>
            <Typography>
                If you have any questions or concerns about these Terms and Conditions, please contact us at <Link href="mailto:bracket@iracing.directory">bracket@iracing.directory</Link>.
            </Typography>
            <Typography>
                By using the website, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.
            </Typography>
        </Box>
    </Dialog>
}

export function TermsAndConditionsLink() {
    const [open, setOpen] = useState(false);
    const showDialog = useCallback(() => {
        setOpen(true);
    }, [setOpen]);
    const onClose = useCallback(() => {
        setOpen(false)
    }, [setOpen]);

    return <>
        <Link
            onClick={showDialog}
            variant="inherit"
            sx={{
                cursor: "pointer"
            }}>Terms and Conditions</Link>
        <TermsAndConditionsDialog open={open} onClose={onClose} />
    </>
}