import type { APISessionSearchInitRequest, LeagueModel } from "api";
import { fetchLeagues } from "../remote";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootDispatch, RootState } from ".";

export const ResultsSlice = createSlice({
  name: "results",
  initialState: {
    /**
     * A cursor that is used to retrieve the next page of results
     */
    cursor: null as string | null,
    /**
     * The results of the search, or null if a search has not been performed
     */
    items: null as LeagueModel[] | null,
    /**
     * The estimated total number of results, if available
     */
    estimatedCount: null as number | null,
    /**
     * True if the next page of results is being fetched
     */
    loadingNextPage: false,
    /**
     * True if all items have been fetched and there are no more pages
     */
    allItemsLoaded: false,

    /** true if the last request to load a page of results failed */
    failed: false,
  },
  reducers: {
    /**
     * Clears the current set of results and resets back to an empty array
     *
     * @param refreshing true if the results are being cleared because new results are on their way immediately
     */
    clearResults: (state, action: PayloadAction<boolean | undefined>) => {
      const refreshing = action.payload ?? false;
      state.cursor = null;
      state.items = null;
      state.estimatedCount = null;
      state.loadingNextPage = refreshing;
      state.allItemsLoaded = false;
      state.failed = false;
    },
    /**
     * Indicates that a request to get the next page of results is underway
     */
    setPending: (state) => {
      state.loadingNextPage = true;
      state.failed = false;
    },
    /**
     * Adds a page of results to the state. Also clears the "pending" flag.
     *
     * @param items the new page of items to add
     * @param nextCursor a cursor to retrieve the next page of items, if there are new pages
     * @param estimatedCount the estimated number of total items, if known
     */
    addPage: (
      state,
      action: PayloadAction<{
        items: LeagueModel[];
        nextCursor: string | null;
        estimatedCount?: number;
      }>
    ) => {
      const { items, nextCursor, estimatedCount } = action.payload;

      state.failed = false;
      if (state.items === null) {
        state.items = [...items];
      } else {
        state.items.push(...items);
      }

      state.cursor = nextCursor;
      if (estimatedCount !== undefined) {
        state.estimatedCount = estimatedCount;
      }
      state.loadingNextPage = false;
      if (nextCursor === null) {
        state.allItemsLoaded = true;
      }
    },
    setFailed: (state) => {
      state.failed = true;
      state.loadingNextPage = false;
    },
  },
});

type ResultsState = ReturnType<(typeof ResultsSlice)['reducer']>;

export const ResultsThunks = {
  /**
   * Begins a new search result request by clearing out the old results and fetching the first page of new ones.
   *
   * If a specific number of results is needed, `await` this one and follow it up with `ensureSize`
   *
   * @param params the initial request to make to the API
   * @returns a promise that resolves when the first page of results is loaded
   */
  executeNewSearch:
    (params: APISessionSearchInitRequest) =>
    async (dispatch: RootDispatch) => {
      dispatch(ResultsSlice.actions.clearResults(true));
      try {
        const response = await fetchLeagues(params);

        dispatch(
          ResultsSlice.actions.addPage({
            items: response.leagues,
            nextCursor: response.next ?? null,
            estimatedCount: response.total,
          })
        );
      } catch (err) {
        dispatch(ResultsSlice.actions.setFailed());
      }
    },
    /**
     * Loads a new page of items. The first page must already have been fetched using `executeNewSearch`.
     */
    loadNextPage: () => async (dispatch: RootDispatch, getState : () => RootState) => {
        const { loadingNextPage, cursor } = getState().results;
        if (loadingNextPage) return;
  
        dispatch(ResultsSlice.actions.setPending());
        if (!cursor) {
          // mark this as the last page if not already marked
          dispatch(ResultsSlice.actions.addPage({items: [], nextCursor: null}));
        } else {
          const response = await fetchLeagues({
            cursor,
          });
          dispatch(
            ResultsSlice.actions.addPage({items: response.leagues,nextCursor: response.next ?? null})
          );
        }
      },
      /**
     * Fetches new pages continuously until there are at least the given number of items in the result or there are no more pages.
     *
     * The first page of results must have already been fetched
     *
     * @param minItems the minimum number of items to save in the results before stopping
     */
    ensureSize: (minItems: number) => async (dispatch: RootDispatch, getState: () => RootState) => {
        while (true) {
          const { items, allItemsLoaded, failed } = getState().results;
          if (
            items === null ||
            items.length >= minItems ||
            allItemsLoaded ||
            failed
          ) {
            // if we have enough items or all items have been loaded already, we're done
  
            // Also, if the first page hasn't been fetched yet, we can't load more pages, so we're done as well
  
            // also, stop if a request fails
            return;
          }
  
          await dispatch(ResultsThunks.loadNextPage());
        }
      },
    
};

export const ResultsSelectors = {
    /**
     * Returns an array of all leagues matching the search query, if loaded
     */
    getLeagues: (state) => state.items,
    /**
     * Returns an estimate of the total number of leagues that match, if known
     */
    getLeagueEstimate: (state) => state.estimatedCount,
    /**
     * Returns true if a new page of results is loading
     */
    isLoading: (state) => state.loadingNextPage,
    /**
     * Returns true if there are no more pages of results to load
     */
    isComplete: (state) => state.allItemsLoaded,
} satisfies Record<string, (_:ResultsState) => any>
