import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { CarConstants } from './carConstants';
import { fetchCarConstants } from "./remote";
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';
import { Provider } from 'react-redux';
import { store } from './store';



const constants = fetchCarConstants();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

constants.then((cc) => {
  root.render(
    <React.StrictMode>
      <CarConstants.Provider value={cc}>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
          <App /></Provider>
        </ThemeProvider>
      </CarConstants.Provider>
    </React.StrictMode>
  );
});

const bg = document.getElementById('bg_image') as HTMLDivElement;
const backgroundImages = [
  'backgrounds/bg1c.webp',
  'backgrounds/bg2c.webp',
  'backgrounds/bg3c.webp',
  'backgrounds/bg4c.webp',
  'backgrounds/bg5c.webp',
  'backgrounds/bg6c.webp',
  'backgrounds/bg7c.webp',
  'backgrounds/bg8c.webp'
];

bg.style.backgroundImage = `url(${backgroundImages[(Math.random() * backgroundImages.length) | 0]}`;

