import {createTheme} from '@mui/material/styles';

export const colors = {
    primary: {
        main:"#4A4A4A",
        "10": "#eee"
    },
    secondary: {
        main: "#f19500",
    }
}

export const theme = createTheme({
    typography:{
        fontFamily: ["Titillium Web"].join(","),
        h1: {
            fontFamily: [
                "Russo One"
            ].join(","),
            fontSize: "3.5rem"
        },
        h2: {
            fontFamily: "Russo One",
            fontSize: "3rem"
        },
        h3: {

        },
        h4: {
            fontSize: "1.5rem",
            fontWeight: "bold",
        },
        h5: {
            fontSize: "1.25rem",
        },
        caption: {
            color: "#777",
        },
    },
    palette: {
        primary: {
            main: colors.primary.main,
        },
        secondary: {
            main: colors.secondary.main,
        }
    },
    components: {
        MuiButtonGroup: {
            styleOverrides: {
                grouped: {
                    minWidth: "26px",
                }
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        backgroundColor: "#E8E8E8"
                    }
                }
            }
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    backgroundColor: colors.primary['10'],
                }
            }
        },
        MuiList: {
            defaultProps: {
                dense: true,
                disablePadding: true,
            }, styleOverrides: {
           
            }
        },
        MuiListItemText: {
            defaultProps: {

            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: "24px",
                }
            }
        },
        MuiCheckbox: {
            defaultProps: {
                size: "small",
            }
        },
        MuiRadio: {
            defaultProps: {
                size: "small",
            }
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    paddingTop: "24px",
                    paddingBottom: "32px",
                    backgroundColor: "#FFFB"
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                }
            }
        },
        MuiCardHeader: {
            defaultProps: {
                titleTypographyProps: {
                    variant:"h4"
                },
                subheaderTypographyProps: {
                    variant: "caption"
                },
            },
            styleOverrides: {
                root: {
                    padding: "8px",
                    paddingBottom: "0px"
                },
                action: {
                    alignSelf: "unset",
                    marginTop: 0,
                    marginBottom: 0,
                    marginRight: 0,
                    
                    display: "flex",
                    alignItems: "center",
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: "8px",
                    paddingBottom: "8px",
                    "&:last-child": {
                        paddingBottom: "8px"
                    }
                },                
            },
        },
        MuiListItemButton: {
            defaultProps: {
                disableGutters: true,
                
            },
            styleOverrides: {
                root: {
                    paddingTop: 0,
                    paddingBottom: 0,
                }
            }

        },
        MuiTable: {
            styleOverrides: {
                root: {
                    border: "1px solid #bbb",
                    backgroundColor: "#fff",
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 8, 
                    paddingRight: 8,
                    verticalAlign: "middle",
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                size: "small",
                variant: "filled",
            },
            styleOverrides: {
                root: {

                }
            }
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    fontWeight: "bold",
                }
            },
            defaultProps: {
                
            }
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true,
                enterTouchDelay: 0,
                leaveTouchDelay: 3000,
            }
        }
    }
});