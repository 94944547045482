import {
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";

import type { APISessionSearchInitRequest } from "api";
import { createRecordSelectors, createRecordSetters } from "../lib/reduxutils";

export type TIME_SEARCH_PERIOD = "days" | "weeks" | "months" | "years";
export type DAYS_OF_WEEK = (APISessionSearchInitRequest["days"] & {})[number];
export type HOURS_OF_DAY =
  | "0"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "10"
  | "11"
  | "12"
  | "13"
  | "14"
  | "15"
  | "16"
  | "17"
  | "18"
  | "19"
  | "20"
  | "21"
  | "22"
  | "23";

export const HOURS_OF_DAY_values: HOURS_OF_DAY[] = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];

function periodToSeconds(duration: number, unit: TIME_SEARCH_PERIOD): number {
  switch (unit) {
    case "days":
      return duration * 60 * 60 * 24;
    case "weeks":
      return periodToSeconds(duration * 7, "days");
    case "months":
      return periodToSeconds(duration * (365.25 / 12), "days");
    case "years":
      return periodToSeconds(duration * 365.25, "days");
  }
}

export type LeagueSortOrders = APISessionSearchInitRequest["order"] & string;

const initialFilterState = {
  includePastSessions: true,
  includeAllPastSessions: false,
  timeWindowPast: 1,
  timeWindowPastPeriod: "months" as TIME_SEARCH_PERIOD,

  includeFutureSessions: true,
  includeAllFutureSessions: true,
  timeWindowFuture: 3,
  timeWindowFuturePeriod: "months" as TIME_SEARCH_PERIOD,

  recruitingOnly: true,
  restrictToLeagueSize: false,
  minimumMemberCount: 50,

  restrictToSof: false,
  minimumSof: 500,
  maximumSof: 5000,

  carIds: new Set<string>(),
  trackLayoutIds: new Set<string>(),
  days: new Set<DAYS_OF_WEEK>(),
  hours: new Set<HOURS_OF_DAY>(),

  orderBy: "session_count" as LeagueSortOrders,
};

export const FilterSlice = createSlice({
  name: "filters",
  initialState: initialFilterState,
  reducers: {
    ...createRecordSetters(initialFilterState),
    toggleRecruitingOnly: (state) => {
      state.recruitingOnly = !state.recruitingOnly;
    },
    selectCars: (state, action: PayloadAction<string[]>) => {
      for (const carId of action.payload) {
        state.carIds.add(carId);
      }
    },
    deselectCars: (state, action: PayloadAction<string[]>) => {
      const carIds = action.payload;
      for (const carId of carIds) {
        state.carIds.delete(carId);
      }
    },
    toggleCar: (state, action: PayloadAction<string>) => {
      const carId = action.payload;
      if (state.carIds.has(carId)) {
        state.carIds.delete(carId);
      } else {
        state.carIds.add(carId);
      }
    },
    toggleRestrictToSize: (state) => {
      state.restrictToLeagueSize = !state.restrictToLeagueSize;
    },
    toggleIncludePast: (state) => {
      state.includePastSessions = !state.includePastSessions;
    },
    toggleIncludeFuture: (state) => {
      state.includeFutureSessions = !state.includeFutureSessions;
    },
    toggleDayOfWeek: (state, action: PayloadAction<DAYS_OF_WEEK>) => {
      const dow = action.payload;
      if (state.days.has(dow)) {
        state.days.delete(dow);
      } else {
        state.days.add(dow);
      }
    },
    toggleTrackLayout: (state, action: PayloadAction<string>) => {
      const tlid = action.payload;
      if (state.trackLayoutIds.has(tlid)) {
        state.trackLayoutIds.delete(tlid);
      } else {
        state.trackLayoutIds.add(tlid);
      }
    },
    deselectTrackLayouts: (state, action: PayloadAction<string[]>) => {
      const tlids = action.payload;
      for (const tlid of tlids) {
        state.trackLayoutIds.delete(tlid);
      }
    },
    toggleHourOfDay: (state, action: PayloadAction<HOURS_OF_DAY>) => {
      const hour = action.payload;
      if (state.hours.has(hour)) {
        state.hours.delete(hour);
      } else {
        state.hours.add(hour);
      }
    },
    toggleRestrictToSof: (state) => {
      state.restrictToSof = !state.restrictToSof;
    },
  },
});

export type FilterState = ReturnType<(typeof FilterSlice)["reducer"]>;

export const FilterSelectors = {
  ...createRecordSelectors(initialFilterState),
  asQueryParams: (filters: FilterState) => {
    const params: APISessionSearchInitRequest = {
      pageSize: 40,
    };

    if (!filters.includePastSessions) {
      params.past = 0;
    } else if (filters.includeAllPastSessions) {
      // leave past undefined
    } else {
      if (filters.timeWindowPast < 0) return null;
      params.past = periodToSeconds(
        filters.timeWindowPast,
        filters.timeWindowPastPeriod
      );
    }

    if (!filters.includeFutureSessions) {
      params.future = 0;
    } else if (filters.includeAllFutureSessions) {
      // leave past undefined
    } else {
      if (filters.timeWindowFuture < 0) return null;
      params.future = periodToSeconds(
        filters.timeWindowFuture,
        filters.timeWindowFuturePeriod
      );
    }

    if (filters.carIds && filters.carIds.size > 0) {
      params.carIds = [...filters.carIds];
    }

    if (filters.trackLayoutIds && filters.trackLayoutIds.size > 0) {
      params.trackLayoutIds = [...filters.trackLayoutIds];
    }

    if (filters.days.size > 0) {
      params.days = [...filters.days];
    }
    if (filters.hours.size > 0) {
      params.hours = [...filters.hours].map((hour) => parseInt(hour));
    }

    if (filters.restrictToSof) {
      params.minSof = filters.minimumSof;
      params.maxSof = filters.maximumSof;
    }

    if (filters.restrictToLeagueSize) {
      params.minimumMemberCount = filters.minimumMemberCount;
    }

    params.includeNonRecruiting = !filters.recruitingOnly;
    params.order = filters.orderBy;
    params.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return params;
  },
} satisfies Record<string, (_:FilterState) => any>;
