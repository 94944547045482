import { useMediaQuery } from "@mui/material";

export function useDevice() {

    const touch = useMediaQuery("(pointer: coarse)");
    const smol = !useMediaQuery("(min-width: 600px)");

    return {
        touch,
        smol,
    }

}