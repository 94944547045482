import { useContext, useState, useMemo, useCallback } from "react";
import { CarConstants } from "../../carConstants";
import { useDispatch, useSelector, Selectors } from "../../store";
import { SxProps, Box, ListItemText, Checkbox, ListItemIcon, List, ListItem, ListItemButton, TextField, Typography, Link, Stack } from "@mui/material";
import { FilterSlice } from "../../store/Filters";
import isEqual from "react-fast-compare";
import React from "react";
import type { APIConstantsResponse, RaceCategory } from "api";
import { Filter } from "./Filter";
import { CategorySelector } from "./CategorySelector";

function TrackSelectionListItem(props: {
    tlId: string,
    selected: boolean
}) {
    const dispatch = useDispatch();
    const { tlId, selected } = props;
    const carConstants = useContext(CarConstants);
    const trackName = getTrackName(tlId, carConstants);

    const toggleSelected = useCallback(() => {
        dispatch(FilterSlice.actions.toggleTrackLayout(tlId));
    }, [dispatch, tlId]);

    return <ListItem key={`tl-${tlId}`} disablePadding disableGutters >
        <ListItemButton role={undefined} onClick={toggleSelected} dense>
            <ListItemIcon sx={{
                minWidth: "24px"
            }}>
                <Checkbox
                    checked={selected}
                    disableRipple size="small" />
            </ListItemIcon>
            <ListItemText primary={trackName} />
        </ListItemButton>
    </ListItem>
}

const TrackSelectionListItemMemo = React.memo(TrackSelectionListItem);

function TrackSelectionMenu(props: {
    shownTrackLayoutIds: string[],
    sx: SxProps
}) {
    const selectedTrackLayouts = useSelector(Selectors.filters.getTrackLayoutIds, isEqual);

    return <Box
        sx={{
            overflowY: "scroll",
            ...props.sx,
        }} className="listbox">
        <List dense disablePadding>
            {props.shownTrackLayoutIds.map((tlId) =>
                <TrackSelectionListItemMemo selected={selectedTrackLayouts.has(tlId)}
                    tlId={tlId} key={`tl-${tlId}`} />
            )}
        </List>
    </Box>

}

function getTrackName(tlid: string, constants: APIConstantsResponse) {
    const trackLayout = constants.trackLayouts[tlid];
    if (trackLayout) {
        if (trackLayout.trackName === trackLayout.name) {
            return trackLayout.name;
        }
        return `${trackLayout.trackName} - ${trackLayout.name}`;
    } else {
        return tlid;
    }
}

function TrackSelectionSummary(props: {
    selectedCars: number,
    shownCars: number,
    totalCars: number,
    onClear: () => void
}) {
    const { selectedCars, shownCars, totalCars, onClear } = props;

    const carCount = selectedCars === 0 ? "All" : selectedCars.toLocaleString();

    return <Box sx={{
        display: "flex",
        gap: 0.5,
        width: "100%"
    }}>
        <Typography variant="caption">{`${carCount} ${selectedCars === 1 ? "track" : "tracks"} selected.`}</Typography>
        {shownCars !== totalCars ? <Typography variant="caption">{`(${shownCars} of ${totalCars} visible)`}</Typography> : null}
        {selectedCars > 0 ? <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
            <Link variant="caption" onClick={onClear} sx={{ cursor: "pointer" }}>Clear</Link>
        </Box> : null}
    </Box>
}

export function TrackFilter() {
    const carConstants = useContext(CarConstants);
    const selectedTracks = useSelector(Selectors.filters.getTrackLayoutIds)
    const dispatch = useDispatch();
    const clearTrackSelections = useCallback(() => {
        dispatch(FilterSlice.actions.setTrackLayoutIds(new Set()));
    }, [dispatch]);

    const [filterBox, setFilterBox] = useState("");

    const [includeRoad, setIncludeRoad] = useState(true);
    const [includeDirtRoad, setIncludeDirtRoad] = useState(true);
    const [includeOval, setIncludeOval] = useState(true);
    const [includeDirtOval, setIncludeDirtOval] = useState(true);

    const sortedTracksByName = useMemo(() => {

        const tracksAndIds = [] as { id: string, name: string, category: RaceCategory }[];
        for (const tlId in carConstants.trackLayouts) {

            const layout = carConstants.trackLayouts[tlId];

            tracksAndIds.push({ id: tlId, name: getTrackName(tlId, carConstants), category: layout.category });
        }

        tracksAndIds.sort((a, b) => {
            const aIsSpecial = a.name.startsWith('[');
            const bIsSpecial = b.name.startsWith('[');

            if (aIsSpecial === bIsSpecial) {
                return (a.name.localeCompare(b.name));
            } else if (aIsSpecial) {
                return 1;
            } else if (bIsSpecial) {
                return -1;
            } else {
                return 0;
            }
        });

        return tracksAndIds;

    }, [carConstants]);

    const filteredTrackIds = useMemo(() => {
        return sortedTracksByName.filter((sc) => {
            if (filterBox !== "" && sc.name.toLowerCase().indexOf(filterBox.toLowerCase()) < 0) {
                return false;
            }

            if (includeRoad && sc.category === "road") {
                return true;
            }
            if (includeOval && sc.category === "oval") {
                return true;
            }
            if (includeDirtRoad && sc.category === "dirt_road") {
                return true;
            }
            if (includeDirtOval && sc.category === "dirt_oval") {
                return true;
            }

            return false;
        }).map((sc) => sc.id);
    }, [sortedTracksByName, filterBox, includeDirtRoad, includeDirtOval, includeRoad, includeOval]);

    const selectedTrackCount = selectedTracks.size;
    const visibleTrackCount = filteredTrackIds.length;
    const totalTrackCount = sortedTracksByName.length;

    return <Filter title="Tracks">
        <TextField
            value={filterBox}
            label="Search track layouts"
            onChange={(ev) => { setFilterBox(ev.target.value); }}
            size="small"
        />
        <Stack sx={{
            width: "100%",
            justifyContent: "space-evenly"
        }} direction="row">
            <CategorySelector
                road={includeRoad} setRoad={setIncludeRoad}
                oval={includeOval} setOval={setIncludeOval}
                dirtRoad={includeDirtRoad} setDirtRoad={setIncludeDirtRoad}
                dirtOval={includeDirtOval} setDirtOval={setIncludeDirtOval}
            />
        </Stack>
        <TrackSelectionMenu shownTrackLayoutIds={filteredTrackIds} sx={{
            flexBasis: 0,
            flexGrow: 2,
            minHeight: "180px",
        }} />
        <TrackSelectionSummary selectedCars={selectedTrackCount} shownCars={visibleTrackCount} totalCars={totalTrackCount} onClear={clearTrackSelections} />
    </Filter>
}