import { List, ListItemButton, ButtonGroup, Button, SxProps, ListItem, ListItemIcon, Checkbox, ListItemText, Typography } from "@mui/material";
import { useDispatch, useSelector, Selectors } from "../../store";
import { DAYS_OF_WEEK, FilterSlice, HOURS_OF_DAY, HOURS_OF_DAY_values } from "../../store/Filters";
import { useCallback } from "react";
import { Filter } from "./Filter";
import { useDevice } from "../../mobile";

function DayOfWeekButton(props: {
    dow: DAYS_OF_WEEK,
    children: string
}) {
    const isSelected = useSelector(Selectors.filters.getDays).has(props.dow);
    const dispatch = useDispatch();

    const { touch } = useDevice();

    const buttonWidth = touch ? "32px" : "24px";
    const buttonHeight = touch ? "32px" : "24px";

    return <Button disableRipple
        sx={{
            padding: 0,
            width: buttonWidth,
            height: buttonHeight,
            minWidth: "unset",
        }}
        variant={isSelected ? "contained" : "outlined"}
        onClick={() => {
            dispatch(FilterSlice.actions.toggleDayOfWeek(props.dow));
        }}>
        {props.children}
    </Button>
}

function DayOfWeekButtons() {

    return <ButtonGroup size="small" sx={{
        alignSelf: "center"
    }}>
        <DayOfWeekButton dow="Sun">S</DayOfWeekButton>
        <DayOfWeekButton dow="Mon">M</DayOfWeekButton>
        <DayOfWeekButton dow="Tue">T</DayOfWeekButton>
        <DayOfWeekButton dow="Wed">W</DayOfWeekButton>
        <DayOfWeekButton dow="Thu">T</DayOfWeekButton>
        <DayOfWeekButton dow="Fri">F</DayOfWeekButton>
        <DayOfWeekButton dow="Sat">S</DayOfWeekButton>
    </ButtonGroup>

}

const HOUR_TIME_FORMAT = new Intl.DateTimeFormat(undefined, {
    timeStyle: "short",
});

function HourOfDayMenuItem(props: {
    hour: HOURS_OF_DAY,
    selected: boolean,
}) {
    const { hour, selected } = props;

    const dispatch = useDispatch();
    const toggleHour = useCallback(() => {
        dispatch(FilterSlice.actions.toggleHourOfDay(hour));
    }, [dispatch, hour]);

    const numericHour = parseInt(hour);
    const displayDate = new Date(2000, 0, 1, numericHour, 0, 0, 0);
    const displayTime = HOUR_TIME_FORMAT.format(displayDate);

    return <ListItem disablePadding disableGutters>
        <ListItemButton role={undefined} onClick={toggleHour} dense>
            <ListItemIcon>
                <Checkbox checked={selected} />
            </ListItemIcon>
            <ListItemText primary={displayTime} />
        </ListItemButton>
    </ListItem>;
}

function TimeOfDayMenu(props: {
    sx?: SxProps
}) {

    const selectedHours = useSelector(Selectors.filters.getHours);

    return <List sx={{ ...props.sx }} className="listbox">
        {
            HOURS_OF_DAY_values.map((hour) => <HourOfDayMenuItem hour={hour} selected={selectedHours.has(hour)} key={`hour-${hour}`} />)
        }
    </List>
}



export function SlotFilter() {


    return <Filter
        title="Time"
        helper="All times are in your local time zone.">
        <Typography marginTop={0.9}>Day of week</Typography>
        <DayOfWeekButtons />
        <Typography>Time of day</Typography>
        <TimeOfDayMenu sx={{
            minHeight: "180px",
            flexBasis: 0,
            flexGrow: 1,
            overflowY: "scroll"
        }} />
    </Filter>
}