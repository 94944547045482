import { Button, ButtonGroup } from "@mui/material";
import React from "react";

function CategorySelectorBase(props: {
    road: boolean,
    oval: boolean,
    dirtRoad: boolean,
    dirtOval: boolean,
    setRoad: (_: boolean) => void,
    setOval: (_: boolean) => void,
    setDirtRoad: (_: boolean) => void,
    setDirtOval: (_: boolean) => void,
}) {
    return <ButtonGroup>
        <Button title="Road" variant={props.road ? "contained" : "outlined"} onClick={() => props.setRoad(!props.road)} size="small">
            <img
                alt="Road"
                src="icons/road-128.png"
                width="24px" height="24px"
                style={{
                    filter: props.road ? "invert(1)" : undefined
                }}
            />
        </Button>
        <Button title="Oval" variant={props.oval ? "contained" : "outlined"} onClick={() => props.setOval(!props.oval)} size="small">
            <img
                alt="Oval"
                src="icons/oval-128.png"
                width="24px" height="24px"
                style={{
                    filter: props.oval ? "invert(1)" : undefined
                }}
            />
        </Button>
        <Button title="Dirt Road" variant={props.dirtRoad ? "contained" : "outlined"} onClick={() => props.setDirtRoad(!props.dirtRoad)} size="small">
            <img
                alt="Dirt Road"
                src="icons/dirt_road-128.png"
                width="24px" height="24px"
                style={{
                    filter: props.dirtRoad ? "invert(1)" : undefined
                }}
            />
        </Button>
        <Button title="Dirt Oval" variant={props.dirtOval ? "contained" : "outlined"} onClick={() => props.setDirtOval(!props.dirtOval)} size="small">
            <img
                alt="Dirt Oval"
                src="icons/dirt_oval-128.png"
                width="24px" height="24px"
                style={{
                    filter: props.dirtOval ? "invert(1)" : undefined
                }}
            />
        </Button>
    </ButtonGroup>
}

export const CategorySelector = React.memo(CategorySelectorBase);