import { createSliceSubselectors } from "../lib/reduxutils";
import { FilterSelectors, FilterSlice } from "./Filters";
import { ResultsSelectors, ResultsSlice, ResultsThunks } from "./Results";
import { TypedUseSelectorHook, useDispatch as useDispatchRedux, useSelector as useSelectorRedux } from "react-redux";
import { StatusSelectors, StatusSlice } from "./Status";

import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { enableMapSet } from "immer";
enableMapSet();

const rootReducer = combineReducers({
    filters: FilterSlice.reducer,
    results: ResultsSlice.reducer,
    status: StatusSlice.reducer,
})

export type RootState = ReturnType<typeof rootReducer>;

export const buildStore = () => configureStore({reducer: rootReducer});

export const store = buildStore();

export type RootDispatch = ReturnType<typeof buildStore>['dispatch'];

export const Selectors = {
    filters: createSliceSubselectors((root: RootState) => root.filters, FilterSelectors),
    results: createSliceSubselectors((root: RootState) => root.results, ResultsSelectors), 
    status: createSliceSubselectors((root: RootState) => root.status, StatusSelectors),
}

export const areFiltersValid = (_:RootState) => Selectors.filters.asQueryParams(_) !== null;



export const useDispatch: () => RootDispatch = useDispatchRedux;
export const useSelector: TypedUseSelectorHook<RootState> = useSelectorRedux;

export const RootThunks = {
    search: () => async (dispatch: RootDispatch, getState: () => RootState) => {
        const searchQuery = Selectors.filters.asQueryParams(getState());
        if(searchQuery) {
            await dispatch(ResultsThunks.executeNewSearch(searchQuery));
        }
    },
    init: () => async (dispatch: RootDispatch, getState: () => RootState) => {
    }
}
