import { useCallback, useMemo, useState, useContext } from "react";
import { useSelector, useDispatch, Selectors } from "../store";
import { ResultsThunks } from "../store/Results";
import type { LeagueModel } from "api";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import React from "react";
import { Button, CircularProgress, Stack, Table, TableBody, TableCell, TableRow, Tooltip, Box } from "@mui/material";

import isEqual from "react-fast-compare";
import { CarConstants } from "../carConstants";
import { IRacingOutLink } from "./IRacingOutlink";
import { useDevice } from "../mobile";

const DATE_FORMAT = new Intl.DateTimeFormat(undefined, {
    dateStyle: "short",
    timeStyle: "short", 
});

function formatDate(date: Date) {
    const dateStr = DATE_FORMAT.format(date);

    // add a non-breaking space between the time and AM/PM so that it wraps together
    return dateStr.replace(" AM", " AM").replace(" PM", " PM");
}

function durationToString(minutes: number) {
    const hours = minutes / 60;

    if (hours >= 1) {
        if (hours === 1) {
            return "1 hour";
        }

        if (hours === (hours | 0)) {
            return hours.toFixed() + " hours";
        } else {
            return hours.toFixed(1) + " hours";
        }
    } else {
        return minutes.toFixed() + " minutes";
    }
}

const CarList = React.memo(function (props: {
    carIds: readonly string[],
    smol?: boolean,
}) {

    const { carIds, smol = false } = props;
    const carNames = useContext(CarConstants).cars;

    let displayCarNames = null as null | string[];
    if (carNames !== undefined) {
        displayCarNames = carIds.map((id) => {
            const car = carNames[id];
            if (car) {
                return car.name;
            } else {
                return id;
            }
        });
    }

    let displayValue = "";
    let showTooltip = false;
    if (carIds.length !== 1 || smol || displayCarNames === null) {
        displayValue = `${carIds.length} car${carIds.length !== 1 ? "s" : ""}`;
        showTooltip = true;
    } else {
        displayValue = displayCarNames[0];
    }


    if (!showTooltip) {
        return <>{displayValue}</>
    } else {
        return <Tooltip title={displayCarNames!.join(", ")} arrow >
            <u>{displayValue}</u>
        </Tooltip>
    }
});

type Season = LeagueModel['seasons'][number];
type Session = Season['sessions'][number];

const SeasonTableFragment = React.memo(function (props: {
    season: Season,
    smol?: boolean
}) {
    const { season, smol = false } = props;

    return <>
        <TableRow>
            <TableCell colSpan={5}>
                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <Typography variant="h5">{season.name}</Typography>
                    {
                        season.retired ? <Typography>(Retired)</Typography> : null
                    }
                </Box>
            </TableCell>
        </TableRow>
        {
            season.sessions.map((session) => <SessionTableRow session={session} smol={smol} />)
        }
    </>
}, isEqual)

function SessionTableRow(props: {
    smol?: boolean,
    session: Session
}) {
    const { session, smol = false } = props;

    const name = session.trackName;
    const date = formatDate(session.startAt);
    const duration = durationToString(session.duration)
    const time = `${date} (${duration})`;

    if (!smol) {

        return <TableRow key={`session-${session.id}`}>
            <TableCell>{name}</TableCell>
            <TableCell>{time}</TableCell>
            <TableCell><CarList carIds={session.cars} /></TableCell>
            <TableCell>{`Drivers: ${session.participants ?? "-"}`}</TableCell>
            <TableCell>{`SoF: ${session.sof ?? "-"}`}</TableCell>
            {/* <TableCell>{ session.participants ? <IRacingOutLink size="small" item="session_results" id={session.id} /> : null}</TableCell> */}
        </TableRow>;
    } else {
        return <>
            <TableRow key={`session-${session.id}-top`} >
                <TableCell colSpan={5} style={{
                    borderBottom: "0px"
                }}>

                    {name}

                </TableCell>
            </TableRow>
            <TableRow key={`session-${session.id}-bottom`}>
                <TableCell colSpan={2}>
                    <Stack direction="column">
                        <Typography variant="inherit">{date}</Typography>
                        <Typography variant="inherit">{duration}</Typography>

                    </Stack>
                </TableCell>
                <TableCell><CarList carIds={session.cars} smol /></TableCell>
                <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                        <img src="icons/helmets-128.png" width="16px" height="16px" alt="Participants" />{session.participants ?? "-"}
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                        <img src="icons/weight-128.png" width="16px" height="16px" alt="Strength of Field" />{session.sof ?? "-"}
                    </Box>
                </TableCell>
            </TableRow>
        </>
    }
}

/**
 * If the given URL is a valid link, then it is wrapped with a link element. Otherwise, it's just text.
 * 
 * @param props 
 * @returns 
 */
function MaybeLink(props: {
    /**
     * destination of the link
     */
    url: string,
    /**
     * text display for the link. Default is to use the url
     */
    title?: string,
}) {
    const { url, title = url } = props;
    let isValidUrl = true;
    try {
        (new URL(url));
    } catch (err) {
        isValidUrl = false;
    }

    if (isValidUrl) {
        return <a href={url} target="_blank" rel="noreferrer" style={{
            wordBreak: "break-word"
        }}>{title}</a>
    } else {
        return <>{title}</>;
    }
}

const LeagueRow = React.memo(function (props: {
    league: LeagueModel,
    smol?: boolean,
}) {
    const { league, smol = false, ...rest } = props;
    const [expanded, setExpanded] = useState(false);

    const sessionCount = useMemo(() => {
        let count = 0;
        for (const season of league.seasons) {
            count += season.sessions.length;
        }
        return count;
    }, [league]);

    const memberCountDisplay = smol
        ? <Stack alignItems="center">
            <img src="./icons/users-128.png" width="16px" height="16px" alt="Members" />
            <Typography>{league.members}</Typography>
        </Stack>
        : <Typography>{`${league.members} ${league.members === 1 ? "member" : "members"}`}</Typography>;

    const sessionCountDisplay = smol
        ? <Stack alignItems="center">
            <img src="./icons/tracks-128.png" width="16px" height="16px" alt="Sessions" />
            <Typography>{sessionCount}</Typography>
        </Stack>
        : <Typography>{`${sessionCount} ${sessionCount === 1 ? "session" : "sessions"}`}</Typography>;

    return <Accordion {...rest} expanded={expanded} onChange={(_ev, isExpanded) => { setExpanded(isExpanded) }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                width: "100%",
                alignItems: "center",
                marginRight: 2
            }}>
                <Typography variant="h4" sx={{ flexGrow: 1, wordBreak: "break-word" }}>{league.name}</Typography>
                {memberCountDisplay}
                {sessionCountDisplay}
            </Box>
        </AccordionSummary>
        <AccordionDetails sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1
        }}>
            <Box sx={{
                display: "flex",
                width: "100%",
            }}>
                <Box sx={{
                    flexGrow: 1,
                }}>
                    <IRacingOutLink id={league.id} item="league" style={{
                        float: "right"
                    }} />
                    {league.about !== null ? <Typography variant="body1" sx={{ wordBreak: "break-word" }}>{league.about}</Typography> : null}
                    {league.url !== null ?
                        <MaybeLink url={league.url} />
                        : null}

                </Box>
            </Box>
            <Table size="small" >
                <TableBody>
                    {
                        league.seasons.map((season) => <SeasonTableFragment key={`season-${season.id}`} season={season} smol={smol} />)
                    }
                </TableBody>
            </Table>
        </AccordionDetails>
    </Accordion>

}, isEqual);

export function ResultsPane() {
    const dispatch = useDispatch();

    const { smol } = useDevice();

    const leagues = useSelector(Selectors.results.getLeagues, isEqual);
    const isLoading = useSelector(Selectors.results.isLoading, isEqual);
    const isComplete = useSelector(Selectors.results.isComplete, isEqual);

    const getMoreLeagues = useCallback(() => {
        dispatch(ResultsThunks.loadNextPage());
    }, [dispatch]);

    return <Stack gap={2} sx={{
        alignItems: "center",
        maxWidth: "100%",
    }}>
        <Stack sx={{ width: "100%" }}>
            {leagues !== null ? leagues.map((league) => <LeagueRow key={`league-${league.id}`} league={league} smol={smol} />) : null}
        </Stack>
        {isLoading ? <CircularProgress /> : null}
        {leagues !== null && !isLoading && !isComplete ? <Button onClick={getMoreLeagues} variant="outlined">Load more</Button> : null}
    </Stack>
}